.modalWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 999;
  backdrop-filter: blur(5px);
  background-color: #00000085;
}

.modal {
  margin: 50px auto;
  width: 30%;
  height: 430px;
  background: white;
  border-radius: 5px;
  animation: fadeInDown 0.8s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@media screen and (max-width: 1025px) {
  .modal {
    margin: auto;
    width: 50%;
    height: 300px;
    background: white;
    border-radius: 5px;
    animation: fadeInDown 0.8s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

@media screen and (max-width: 770px) {
  .modal {
    margin: auto;
    width: 60%;
    height: 300px;
    background: white;
    border-radius: 5px;
    animation: fadeInDown 0.8s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

@media screen and (max-width: 426px) {
  .modal {
    margin: auto;
    width: 90%;
    height: 245px;
    background: white;
    border-radius: 5px;
    animation: fadeInDown 0.8s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
