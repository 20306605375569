@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Barlow", sans-serif;
}

#scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
  border-radius: 50px;
}

#scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  border-radius: 50px;
}

#scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 50px;
}

#scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 50px;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar {
  width: 0px;
}

.scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2px;
}

input[type="file"].custom {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 100px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
  background-color: #ffff;
}

.weather-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
}

.weather-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.city-details {
  font-size: 0.8em;
}
